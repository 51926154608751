.pictureFrame {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: auto;
	margin: var(--default-margin);
	font-size: 0px;
	max-width: 90%;
	background-color: white;
	box-sizing: border-box;
	padding: 2px;
}
