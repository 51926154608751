.titleText {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: auto;
	margin: var(--default-margin);
	padding: 2rem;
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: xx-large;
	background-color: white;
	color: #d6000d;
}
