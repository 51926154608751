.scrollContainer {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: white;
	z-index: 1;
}

.scrollPercentage {
	font-family: var(--main-font-family);
	font-size: medium;
	border-top: var(--thick-border);
	border-left: var(--thick-border);
	border-right: var(--thick-border);
}
.arrowDown {
	border: var(--thick-border);
	text-align: center;
	background-color: #8f0e20;
}
