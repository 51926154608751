.container {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: large;
	width: 100%;
	background-color: #8f0e20;
	color: white;
	text-align: center;
	border: var(--thick-border);
	padding: 1rem;
}

.container:hover {
	opacity: 0.9;
}
