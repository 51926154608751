@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.StyledButton_container__3F9A8 {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: large;
	width: 100%;
	background-color: #8f0e20;
	color: white;
	text-align: center;
	border: var(--thick-border);
	padding: 1rem;
}

.StyledButton_container__3F9A8:hover {
	opacity: 0.9;
}

.ScrollButton_scrollContainer__3N8EM {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: white;
	z-index: 1;
}

.ScrollButton_scrollPercentage__22gJC {
	font-family: var(--main-font-family);
	font-size: medium;
	border-top: var(--thick-border);
	border-left: var(--thick-border);
	border-right: var(--thick-border);
}
.ScrollButton_arrowDown__u419G {
	border: var(--thick-border);
	text-align: center;
	background-color: #8f0e20;
}

.DoubleColumn_doubleColumn__RkFwC {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.DoubleColumn_singleColumn__2UHuA {
	display: grid;
	grid-template-columns: 1fr;
}

.DoubleColumn_leftElement__2FbxW {
	/* justify-content: flex-start; */
	justify-content: center;
}
.DoubleColumn_rightElement__gf9lD {
	justify-content: center;
}

.DoubleColumn_leftElement__2FbxW,
.DoubleColumn_rightElement__gf9lD {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}

.SingleColumn_singleColumn__1_4QU {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.TextBox_TextBoxText__2PBe8 {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: var(--default-margin);
	padding: 0.5rem;
	font-family: var(--main-font-family);
	font-weight: 400;
	font-size: medium;
	background-color: white;
}

.PictureFrame_pictureFrame__2dMG5 {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: auto;
	margin: var(--default-margin);
	font-size: 0px;
	max-width: 90%;
	background-color: white;
	box-sizing: border-box;
	padding: 2px;
}

.Title_titleText__3YFpO {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: auto;
	margin: var(--default-margin);
	padding: 2rem;
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: xx-large;
	background-color: white;
	color: #d6000d;
}

.YoutubePlayer_playerContainer__1-Pg0 {
	box-shadow: var(--drop-shadow);
	font-size: 0;
}

.Chapter4_freeTextInputLabel__38Nva {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 1rem;
}

.Chapter4_freeTextInput__1CM_I {
	width: 80vw;
	height: 70vh;
	font-family: var(--main-font-family);
	font-size: 1.5rem;
}

.LikertScaleInput_likertContainer__1IbpI {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding: 0.5rem;
	margin: var(--default-margin);
	width: 90%;
	background-color: white;
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
}

.LikertScaleInput_questionText__3eXIP {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: medium;
	margin: 0;
}

.LikertScaleInput_optionsContainer__12OFm {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.LikertScaleInput_option__26qC8 {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: var(--main-font-family);
	font-size: small;
	opacity: 0.9;
	text-align: center;
	margin: 0 0.5rem 0 0.5rem;
}

.LikertScaleInput_option__26qC8 input {
	height: 1.5rem;
	width: 1.5rem;
}

@media only screen and (max-width: 768px) {
	.LikertScaleInput_likertContainer__1IbpI {
		padding: 0.25rem;
		margin: 0.2rem;
		padding: var(--default-margin);
	}

	.LikertScaleInput_questionText__3eXIP {
		font-family: var(--main-font-family);
		font-weight: 700;
		font-size: medium;
	}

	.LikertScaleInput_optionsContainer__12OFm {
		flex-direction: column;
		align-items: flex-start;
	}

	.LikertScaleInput_option__26qC8 {
		flex-direction: row-reverse;
		text-align: center;
		margin: 0.5rem 0 0.5rem 0;
	}

	.LikertScaleInput_option__26qC8 input {
		margin-right: 0.5rem;
	}
}

.Chapter6_formErrorMessage__2WC_T {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: 2.5em;
	color: #c0580c;
	text-align: center;
}

.Chapter6_form__3hq1W {
	display: flex;
	flex-direction: column;
	align-items: center;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
	--main-font-family: "Roboto", Arial, sans-serif;
	--drop-shadow: 5px 5px 10px rgb(0 0 0 / 0.5);
	--thick-border: 5px solid black;
	--default-margin: 1rem 1.5rem 0.5rem 1.5rem;
}

/* body::-webkit-scrollbar {
	display: none;
} */

