.doubleColumn {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.singleColumn {
	display: grid;
	grid-template-columns: 1fr;
}

.leftElement {
	/* justify-content: flex-start; */
	justify-content: center;
}
.rightElement {
	justify-content: center;
}

.leftElement,
.rightElement {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}
