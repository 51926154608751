.formErrorMessage {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: 2.5em;
	color: #c0580c;
	text-align: center;
}

.form {
	display: flex;
	flex-direction: column;
	align-items: center;
}
