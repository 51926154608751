.freeTextInputLabel {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: 2rem;
	margin-bottom: 1rem;
}

.freeTextInput {
	width: 80vw;
	height: 70vh;
	font-family: var(--main-font-family);
	font-size: 1.5rem;
}
