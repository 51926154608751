.likertContainer {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	padding: 0.5rem;
	margin: var(--default-margin);
	width: 90%;
	background-color: white;
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
}

.questionText {
	font-family: var(--main-font-family);
	font-weight: 700;
	font-size: medium;
	margin: 0;
}

.optionsContainer {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.option {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: var(--main-font-family);
	font-size: small;
	opacity: 0.9;
	text-align: center;
	margin: 0 0.5rem 0 0.5rem;
}

.option input {
	height: 1.5rem;
	width: 1.5rem;
}

@media only screen and (max-width: 768px) {
	.likertContainer {
		padding: 0.25rem;
		margin: 0.2rem;
		padding: var(--default-margin);
	}

	.questionText {
		font-family: var(--main-font-family);
		font-weight: 700;
		font-size: medium;
	}

	.optionsContainer {
		flex-direction: column;
		align-items: flex-start;
	}

	.option {
		flex-direction: row-reverse;
		text-align: center;
		margin: 0.5rem 0 0.5rem 0;
	}

	.option input {
		margin-right: 0.5rem;
	}
}
