.TextBoxText {
	border: var(--thick-border);
	box-shadow: var(--drop-shadow);
	width: fit-content;
	height: fit-content;
	margin: var(--default-margin);
	padding: 0.5rem;
	font-family: var(--main-font-family);
	font-weight: 400;
	font-size: medium;
	background-color: white;
}
